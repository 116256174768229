import { Component } from '@angular/core';

@Component({
  selector: 'main-footer',
  standalone: true,
  imports: [],
  templateUrl: './main-footer.component.html',
  styleUrl: './main-footer.component.scss'
})
export class MainFooterComponent {
  public year: number = new Date().getFullYear();
}
