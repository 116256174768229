<div class="container">
  <footer id="main-footer" class="my-4 px-4 py-4 text-bg-secondary rounded-3">
    <div class="d-flex flex-column align-items-center">
      <figure class="logotype m-0" routerLink="/" role="button">
        <img src="./assets/images/logotype-color.svg" alt="Solviing Logotype" width="70px">
      </figure>

      <p class="copyright fw-medium m-0"><small>2021 - {{year}} Solviing® Todos los derechos reservados.</small></p>

      <ul class="links list-unstyled d-flex align-items-center m-0">
        <li class="links-items fw-medium">
          <a href="https://" class="">Términos y Condiciones</a>
        </li>
        <li class="links-items fw-medium">
          <a href="https://" class="">Aviso de Privacidad</a>
        </li>
      </ul>
    </div>

    <p class="m-0 mt-4 text-center w-lg-75 mx-auto"><small>Usar este sitio implica que aceptas nuestras Politicas y
        Términos, Aviso de Privacidad y Politica datos de Navegación. Prohibida su reproduccion total o parcial, asi
        como su traducción a cualquier idioma sin autorización escrita de su titular.</small></p>
    <p class="m-0 text-center"><small>*El tiempo estipulado depende del proceso que requiere el inmueble.</small>
    </p>
  </footer>
</div>